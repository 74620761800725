import { head } from 'ramda';
import { Market } from '@bridebook/toolbox/src/gazetteer';
import { Query } from 'lib/types';
import { UrlHelper } from 'lib/url-helper';

const getLPRedirect = ({ asPath }: { asPath: string }) => {
  const lpRoutes = [
    { path: '/guestlist', src: 'planning-tools/guestlist' },
    { path: '/checklist', src: 'planning-tools/checklist' },
    { path: '/budget', src: 'planning-tools/budget' },
    { path: '/shortlist', src: 'planning-tools/supplier-shortlist' },
  ];
  const found = head(lpRoutes.filter(({ src }) => asPath.includes(src)));
  return found ? { path: found.path, redirect: true } : null;
};

export type GetRedirectTypes = {
  query: Query;
  asPath: string;
  signedUp?: boolean;
  pathname: string;
  market: Market;
  previousQuery: Query;
  loggedOutEnquiryTriggered?: boolean;
} & (
  | {
      isCollaborator: true;
      redirectURLAfterLoginOrSignup?: string;
    }
  | {
      isCollaborator: false;
    }
);

interface GetRedirectReturnTypes {
  as?: string;
  path: string;
  redirect: boolean;
  next?: string;
}

export const getRedirect = ({
  query,
  asPath,
  signedUp,
  pathname,
  market,
  loggedOutEnquiryTriggered,
  previousQuery,
  ...rest
}: GetRedirectTypes): GetRedirectReturnTypes => {
  /**
   * We want to exclude inbox panel from redirect, because the quick responses panel url is generated
   * from the supplier's market to match supplier's language. Thus, there could be a situation when
   * e.g. French user with English language is contacting Irish supplier - in this case the quick
   * responses url contains /ie/ market, and we want to prevent the redirect.
   */

  if (pathname.includes(UrlHelper.inboxPanel())) return { redirect: false, path: '' };

  if (rest.isCollaborator) {
    return {
      path: UrlHelper.collaboratorOnboarding,
      redirect: true,
      ...(rest.redirectURLAfterLoginOrSignup && { next: rest.redirectURLAfterLoginOrSignup }),
    };
  }

  if (loggedOutEnquiryTriggered && query.next) {
    return {
      path: query.next,
      redirect: false,
    };
  }

  // Redirect to onboarding after signup
  if (signedUp) {
    return {
      path: UrlHelper.onboarding,
      redirect: true,
      ...(query && query.next && { next: query.next }),
    };
  }

  // SEO LP redirects
  const isLPredirect = getLPRedirect({ asPath });
  if (isLPredirect) return isLPredirect;

  /**
   * Redirect the user from those following paths
   */
  const redirectFrom = [
    UrlHelper.index,
    UrlHelper.changePassword,
    UrlHelper.signup,
    UrlHelper.login,
  ];

  const shouldRedirectToHome = redirectFrom.some((p) => pathname.endsWith(p));

  const resetemail = Boolean(previousQuery?.resetemail);
  const updatedPath = shouldRedirectToHome
    ? UrlHelper.home
    : `/${asPath.replace(/^[/][^/]+[/]/, '')}`;

  // check if `?next=` path is present in url,
  // otherwise redirect to /home ( from root landing page )
  const path = query && query.next ? `/${query.next}` : updatedPath;

  const redirect = shouldRedirectToHome || resetemail || query.market !== market.prefix;

  return { path, redirect };
};
